<template>
  <div class="card movement-route-lesson-section">
    <div
      class="h-100 d-flex align-items-center svg-icon svg-icon-sm text-overflow"
    >
      <inline-svg
        class="svg-icon mr-2 mw-30 sortHandle"
        src="/media/svg/icons/Neolex/Basic/menu-dots.svg"
      />
    </div>
    <div class="movement-route-lesson-section__name">
      {{ section.name }}
    </div>
    <div class="movement-route-lesson-section__name">
      Lặp lại {{ section.replayTime }} lần
    </div>
    <div class="movement-route-lesson-section__actions">
      <b-button
        class="movement-route-lesson-section__actions__button mr-2"
        size="sm"
        variant="info"
        @click="onClickEditButton"
      >
        <span class="svg-icon">
          <inline-svg
            class="movement-route-lesson-section__actions__button__icon"
            src="/media/svg/icons/Neolex/Basic/edit-2.svg"
          />
        </span>
      </b-button>
      <b-button
        class="movement-route-lesson-section__actions__button"
        size="sm"
        variant="danger"
        @click="onClickDeleteButton"
      >
        <span class="svg-icon">
          <inline-svg
            class="movement-route-lesson-section__actions__button__icon"
            src="/media/svg/icons/Neolex/Basic/trash-2.svg"
          />
        </span>
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MovementRouteLessonSection',
  props: {
    section: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    onClickEditButton() {
      this.$emit('edit', this.section);
    },
    onClickDeleteButton() {
      this.$emit('delete');
    },
  },
};
</script>

<style lang="scss" scoped>
.movement-route-lesson-section {
  display: grid;
  grid-template-columns: max-content 1fr 1fr max-content;
  align-items: center;
  column-gap: 16px;
  height: 50px;
  padding: 10px;
  background-color: #fafafa;
  margin-bottom: 16px;
  cursor: move !important;
  cursor: -webkit-grabbing !important;

  .movement-route-lesson-section__name {
    flex: 0 0 250px;
    color: #000000;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .movement-route-lesson-section__actions {
    .movement-route-lesson-section__actions__view-button {
      background-color: #def2f1;
      border-color: #def2f1;
      color: #008479;
    }

    .movement-route-lesson-section__actions__button {
      height: 32px;
      width: 32px;

      .movement-route-lesson-section__actions__button__icon {
        height: 12px;
        width: 12px;
      }
    }
  }
}
</style>
