var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"movement-route-lesson-section-edit-modal","title":_vm.titleModal,"size":"xl","centered":""},on:{"hide":_vm.resetModal}},[_c('b-container',[_c('form',{attrs:{"id":"lesson-section-edit-form"},on:{"submit":function($event){$event.preventDefault();return _vm.handleValidate.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{staticClass:"border-right"},[_c('basic-input',{directives:[{name:"validate",rawName:"v-validate",value:({
              required: true,
              alpha_num: true,
              max: 50,
              valid_code: _vm.validCode,
            }),expression:"{\n              required: true,\n              alpha_num: true,\n              max: 50,\n              valid_code: validCode,\n            }"}],attrs:{"required":true,"label":"Mã nhóm động tác","name":"code","placeholder":"Nhập mã nhóm động tác","state":_vm.validateState('code'),"invalidFeedback":_vm.errors.first('code'),"data-vv-as":"Mã nhóm động tác","maxlength":"50"},model:{value:(_vm.form.code),callback:function ($$v) {_vm.$set(_vm.form, "code", $$v)},expression:"form.code"}}),_c('basic-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|max:100'),expression:"'required|max:100'"}],attrs:{"required":true,"label":"Tên nhóm động tác","name":"name","placeholder":"Nhập tên nhóm động tác","state":_vm.validateState('name'),"invalidFeedback":_vm.errors.first('name'),"data-vv-as":"Tên nhóm động tác","maxlength":"100"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('basic-select',{attrs:{"label":"Mã vận động","name":"exerciseCategoryId","options":_vm.exerciseCategoryOptions,"placeholder":"---Chọn mã vận động---","value":_vm.form.exerciseCategoryId,"track-by":"value","value-label":"text"},on:{"update:value":[function($event){return _vm.$set(_vm.form, "exerciseCategoryId", $event)},function($event){return _vm.selected($event, 'exerciseCategoryId', _vm.exerciseCategoryOptions)}]}}),(_vm.form.exerciseCategoryId)?_c('div',[_vm._v(" Tên vận động: "+_vm._s(_vm.form.exerciseCategoryId.name)+" ")]):_vm._e()],1),_c('b-col',[_c('label',[_vm._v("Video động tác "),_c('span',{staticStyle:{"color":"#ff0000"}},[_vm._v("*")])]),(!_vm.form.videoUrl)?_c('basic-file-input',{directives:[{name:"validate",rawName:"v-validate",value:({
              required: !_vm.form.videoUrl && !_vm.form.videoFile,
            }),expression:"{\n              required: !form.videoUrl && !form.videoFile,\n            }"}],staticClass:"mb-6",attrs:{"title":"Tải lên video","name":"video","state":_vm.validateState('video'),"invalidFeedback":_vm.errors.first('video'),"data-vv-as":"Video động tác"},on:{"uploaded":_vm.handleUploaded},scopedSlots:_vm._u([{key:"uploadedFile",fn:function(){return [(_vm.videoPreview)?_c('video',{staticClass:"w-100",attrs:{"id":"video","src":_vm.videoPreview,"controls":""}}):_vm._e()]},proxy:true}],null,false,2927637263)}):_vm._e(),(!_vm.videoPreview)?[_c('basic-input',{directives:[{name:"validate",rawName:"v-validate",value:({
                required: !_vm.form.videoFile && !_vm.form.videoUrl,
                valid_video_url: true,
              }),expression:"{\n                required: !form.videoFile && !form.videoUrl,\n                valid_video_url: true,\n              }"}],attrs:{"name":"videoUrl","placeholder":"Nhập đường dẫn video","state":_vm.validateState('videoUrl'),"invalidFeedback":_vm.errors.first('videoUrl'),"data-vv-as":"Đường dẫn video"},model:{value:(_vm.form.videoUrl),callback:function ($$v) {_vm.$set(_vm.form, "videoUrl", $$v)},expression:"form.videoUrl"}}),(_vm.form.videoUrl)?_c('video',{staticClass:"w-100 mb-4",attrs:{"id":"video","src":_vm.form.videoUrl,"controls":""}}):_vm._e()]:_vm._e(),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('basic-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"required":true,"label":"Số lần replay video","options":_vm.replayTimeOptions,"value-label":"text","track-by":"value","allow-empty":false,"placeholder":"--- Chọn số lần replay ---","value":_vm.form.replayTime,"state":_vm.validateState('replayTime'),"invalidFeedback":_vm.errors.first('replayTime'),"data-vv-as":"Số lần replay video"},on:{"update:value":[function($event){return _vm.$set(_vm.form, "replayTime", $event)},function($event){return _vm.selected($event, 'replayTime', _vm.replayTimeOptions)}]}})],1)],1)],2)],1)],1)]),_c('template',{slot:"modal-footer"},[_c('div',{staticClass:"d-flex justify-content-end align-items-center"},[_c('b-button',{staticClass:"btn mr-3",on:{"click":_vm.onClickCancelButton}},[_vm._v(" Hủy")]),_c('b-button',{staticClass:"btn btn-success",attrs:{"type":"submit","form":"lesson-section-edit-form"}},[_c('span',{staticClass:"svg-icon"},[_c('inline-svg',{attrs:{"src":"/media/svg/icons/Neolex/Basic/save.svg"}})],1),_vm._v(" Lưu ")])],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }