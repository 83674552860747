<template>
  <div class="movement-route-lesson-edit-page w-100">
    <basic-subheader>
      <template v-slot:actions>
        <b-button class="btn ml-3" type="button" @click="onClickCancelButton">
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Arrows/arrow-left.svg" />
          </span>
          Hủy
        </b-button>
        <b-button
          class="btn btn-success ml-3"
          type="submit"
          form="movement-route-lesson-edit-form"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
          </span>
          Lưu lại
        </b-button>
      </template>
    </basic-subheader>
    <b-overlay :show="loading">
      <b-container
        fluid
        class="movement-route-lesson-edit-page__body mb-6 mt-6"
      >
        <b-row>
          <b-col>
            <div class="card card-custom gutter-b h-100">
              <div class="card-body mt-0">
                <form
                  id="movement-route-lesson-edit-form"
                  @submit.prevent="handleValidate"
                >
                  <b-row>
                    <b-col>
                      <div class="image-upload">
                        <v-file-input
                          hide-input
                          v-model="file"
                          accept="image/png, image/jpeg"
                          class="icon-edit"
                          @change="onUpload"
                          prepend-icon="mdi-pencil-outline"
                          truncate-length="15"
                        ></v-file-input>
                        <v-img :src="preview" :aspect-ratio="1 / 1"></v-img>
                      </div>
                      <div class="error text-error">
                        <p v-if="showImageError">
                          Bạn cần đổi hình ảnh mặc định để lưu lộ trình này
                        </p>
                        <p v-if="!isImageValid" class="mb-1">
                          Kích thước ảnh tối đa nhỏ hơn 512kb
                        </p>
                        <p v-if="!isImage" class="mb-1">
                          Định dạng ảnh phải là JPG hoặc PNG
                        </p>
                      </div>
                      <label
                        class="d-flex flex-column align-items-center justify-content-center"
                      >
                        <span
                          class="mb-1"
                          style="font-weight: bold; font-size: 16px"
                          >Hình ảnh bài tập (tỷ lệ 1:1)
                          <span style="color: red">*</span>
                        </span>
                        <span class="d-block"
                          >Tối đa 512kb, kích thước đề xuất 320:320px</span
                        >
                      </label>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <basic-input
                        :required="true"
                        label="Mã bài tập"
                        name="code"
                        placeholder="Nhập mã bài tập"
                        :value.sync="form.code"
                        maxlength="50"
                        :state="validateState('code')"
                        :invalidFeedback="errors.first('code')"
                        v-validate="'required|alpha_num'"
                        data-vv-as="Mã bài tập"
                      />
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <basic-input
                        :required="true"
                        label="Tên bài tập"
                        name="name"
                        placeholder="Nhập tên bài tập"
                        :value.sync="form.name"
                        maxlength="100"
                        :state="validateState('name')"
                        :invalidFeedback="errors.first('name')"
                        v-validate="'required'"
                        data-vv-as="Tên bài tập"
                      />
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <basic-text-area
                        :required="true"
                        label="Nội dung mô tả ngắn gọn"
                        name="description"
                        placeholder="Nhập nội dung mô tả bài tập"
                        maxlength="500"
                        :value.sync="form.description"
                        :state="validateState('description')"
                        :invalidFeedback="errors.first('description')"
                        v-validate="'required'"
                        data-vv-as="Nội dung mô tả ngắn gọn"
                      />
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <basic-input
                        label="Thời gian tập (phút)"
                        required
                        name="practiceTime"
                        input-number
                        placeholder="Nhập thời gian tập thỏa mãn"
                        :value.sync="form.practiceTime"
                        :state="validateState('practiceTime')"
                        :invalidFeedback="errors.first('practiceTime')"
                        v-validate="{
                          required: true,
                          regex: REGEX.IS_NUMBER,
                          max: 10,
                        }"
                        data-vv-as="Thời gian tập"
                        maxlength="10"
                      />
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12">
                      <label>Video hướng dẫn</label>
                      <basic-file-input
                        v-if="!form.videoUrl"
                        title="Tải lên video"
                        @uploaded="handleUploaded"
                      >
                        <template v-slot:uploadedFile>
                          <video
                            v-if="videoPreview"
                            :src="videoPreview"
                            class="w-100"
                            controls
                          ></video>
                        </template>
                      </basic-file-input>
                    </b-col>
                    <b-col v-if="!videoPreview" cols="12">
                      <basic-input
                        name="videoUrl"
                        placeholder="---Nhập đường dẫn video---"
                        :value.sync="form.videoUrl"
                        :state="validateState('videoUrl')"
                        :invalidFeedback="errors.first('videoUrl')"
                        v-validate="'valid_video_url'"
                        data-vv-as="Đường dẫn video"
                      >
                      </basic-input>
                      <video
                        v-if="form.videoUrl"
                        :src="form.videoUrl"
                        class="w-100"
                        controls
                      ></video>
                    </b-col>
                  </b-row>
                </form>
              </div>
            </div>
          </b-col>
          <b-col>
            <div class="card card-custom gutter-b h-100">
              <div class="card-body mt-0">
                <b-row>
                  <b-col class="d-flex justify-content-end">
                    <!-- <b-button class="btn button-info ml-2" type="button">
                      <span class="svg-icon">
                        <inline-svg
                          src="/media/svg/icons/Neolex/Basic/eye.svg"
                        />
                      </span>
                      Xem trước video bài tập
                    </b-button> -->
                    <b-button
                      class="btn btn-success ml-2"
                      type="button"
                      @click="onClickSelectSectionButton"
                    >
                      <span class="svg-icon">
                        <inline-svg
                          src="/media/svg/icons/Neolex/Basic/plus.svg"
                        />
                      </span>
                      Chọn nhóm động tác có sẵn
                    </b-button>
                    <b-button
                      class="btn btn-success ml-2"
                      type="button"
                      @click="onClickAddSectionButton"
                    >
                      <span class="svg-icon">
                        <inline-svg
                          src="/media/svg/icons/Neolex/Basic/plus.svg"
                        />
                      </span>
                      Thêm nhóm động tác mới
                    </b-button>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <div ref="sortableSections">
                      <!--component sortable-->
                      <movement-route-lesson-section
                        v-for="(section, index) in lessonSections"
                        class="sortable"
                        :key="`${section.code}-${$uuid.v1()}`"
                        :section="section"
                        @edit="onClickEditLessonSection(section, index)"
                        @delete="showConfirmDelete(index, section)"
                      />
                    </div>
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-overlay>
    <movement-route-lesson-section-select
      @select="callbackSelectLessonSection"
    />
    <movement-route-lesson-section-edit
      :section="selectedSection"
      :lesson-sections="lessonSections"
      :type="modalType"
      @edit="callbackEditLessonSection"
      @create="callbackCreateLessonSection"
      @reset="resetModal"
    />
  </div>
</template>

<script>
import { MODAL_TYPE, REGEX } from '@/core/plugins/constants';
import Sortable from 'sortablejs';
import MovementRouteLessonSection from './components/MovementRouteLessonSection';
import MovementRouteLessonSectionEdit from './MovementRouteLessonSectionEdit';
import differenceWith from 'lodash/differenceWith';
import intersectionWith from 'lodash/intersectionWith';
import isEqual from 'lodash/isEqual';
import BasicFileInput from '../lesson/BasicFileInput';
import MovementRouteLessonSectionSelect from './MovementRouteLessonSectionSelect';
import sortBy from 'lodash/sortBy';
import { createNamespacedHelpers } from 'vuex';
import { CREATE_SECTION } from '@/core/services/store/movementRoute.module';
import { CONTAINER_FILE_NAME } from '../../../core/plugins/constants';
const { mapActions } = createNamespacedHelpers('movementRoute');

export default {
  name: 'MovementRouteLessonEdit',
  components: {
    MovementRouteLessonSectionSelect,
    BasicFileInput,
    MovementRouteLessonSection,
    MovementRouteLessonSectionEdit,
  },
  data() {
    return {
      REGEX,
      url: {
        create: '/ExerciseMovement',
        edit: '/ExerciseMovement',
        detail: '/ExerciseMovement',
      },
      file: null,
      isImageValid: true,
      isImage: true,
      preview: null,
      loading: false,

      form: {
        code: null,
        name: null,
        coverId: null,
        description: null,
        practiceTime: null,
        isFree: false,
        videoFile: null,
        videoUrl: null,
        ratable: false,
      },

      lessonSections: [],
      originalLessonSections: [],
      selectedSection: null,
      selectedSectionIndex: null,
      modalType: null,
      showImageError: false,
      videoPreview: null,
      listCreatedSections: [],
      createLessonSuccess: true,
    };
  },
  computed: {
    isCreateMode() {
      return this.$route.name === 'movement_route_lesson_create';
    },
    agendaId() {
      return this.$route.params.id;
    },
    lessonId() {
      return this.$route.params.lessonId;
    },
    day() {
      return this.$route.params.day;
    },
    isInserted() {
      return this.$route.query.isInserted || false;
    },
  },
  created() {
    if (!this.isCreateMode) {
      this.loadData();
    }
  },
  mounted() {
    new Sortable(this.$refs.sortableSections, {
      draggable: '.sortable',
      handle: '.sortable',
      onEnd: this.dragReorder,
    });
  },
  beforeDestroy() {
    if (!this.createLessonSuccess && this.listCreatedSections.length) {
      this.loading = true;
      this.lessonSections
        .filter((el) => !el.source)
        .forEach(async (el) => {
          await this.deleteSection(el);
        });
      this.loading = false;
    }
  },
  methods: {
    ...mapActions({ CREATE_SECTION }),
    async callbackSelectLessonSection(section) {
      // if (this.lessonSections.some((item) => item.id === section.id)) {
      //   this.$toastr.e({
      //     title: 'Lỗi',
      //     msg: 'Động tác đã tồn tại',
      //     preventDuplicates: true,
      //   });
      // } else {
      //   }
      if (!this.lessonId) {
        this.listCreatedSections.push(section.id);
      } else {
        await this.addAvaiableSection(section);
      }
      this.lessonSections.push({ ...section, source: 'Avaiable' });
    },
    async addAvaiableSection(section) {
      try {
        this.loading = true;
        const payload = {
          exerciseMovementId: this.lessonId,
          exerciseMovementSectionId: section.id,
          order: this.lessonSections.length,
        };
        const { meta, error } = await this.$api.post(
          'ExerciseMovement/Sections/CreateMappings',
          payload,
        );

        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi',
            msg: error.message,
            preventDuplicates: true,
          });
        }
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi',
          msg: error.message,
          preventDuplicates: true,
        });
      } finally {
        this.loading = false;
      }
    },
    async deleteSectionMapping(section) {
      try {
        this.loading = true;
        const payload = {
          exerciseMovementId: this.lessonId,
          exerciseMovementSectionId: section.id,
          order: section.order,
        };
        const { meta, error } = await this.$api.post(
          'ExerciseMovement/Sections/DeleteMappings',
          payload,
        );

        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi',
            msg: error.message,
            preventDuplicates: true,
          });
        }
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi',
          msg: error.message,
          preventDuplicates: true,
        });
      } finally {
        this.loading = false;
      }
    },
    async deleteSection(section) {
      try {
        this.loading = true;
        const { meta, error } = await this.$api.delete(
          `ExerciseMovement/Sections/${section.id}`,
        );

        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi',
            msg: error.message,
            preventDuplicates: true,
          });
        }
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi',
          msg: error.message,
          preventDuplicates: true,
        });
      } finally {
        this.loading = false;
      }
    },
    async createSection(section) {
      this.loading = true;
      let videoUrl = section.videoFile
        ? await this.uploadVideo(
            section.videoFile,
            CONTAINER_FILE_NAME.EXERCISE,
          )
        : null;
      const payload = {
        ...section,
        order: this.lessonSections.length,
        videoUrl: videoUrl || section.videoUrl,
      };
      const { error, data } = await this.CREATE_SECTION(payload);

      if (error) {
        this.loading = false;
        return this.$toastr.e({
          title: 'Lỗi',
          msg: error,
          preventDuplicates: true,
        });
      }
      this.listCreatedSections.push(data.id);
      this.loading = false;
      return data.id;
    },
    async updateSection(section) {
      try {
        this.loading = true;
        let videoUrl = section.videoFile
          ? await this.uploadVideo(
              section.videoFile,
              CONTAINER_FILE_NAME.EXERCISE,
            )
          : null;
        const payload = {
          ...section,
          videoUrl: videoUrl || section.videoUrl,
        };
        const { meta, error } = await this.$api.put(
          `ExerciseMovement/Sections/${section.id}`,
          payload,
        );

        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi',
            msg: error.message,
            preventDuplicates: true,
          });
        }
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi',
          msg: error.message,
          preventDuplicates: true,
        });
      } finally {
        this.loading = false;
      }
    },
    async uploadVideo(file, containerName) {
      // upload file
      const { error, data } = await this.$store.dispatch(
        'context/UPLOAD_VIDEO_FILE',
        {
          file,
          containerName,
        },
      );
      if (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error,
          preventDuplicates: true,
        });
        return null;
      }
      return data;
    },
    getUrlFromFile(file, supportedType = 'video/mp4') {
      if (file && file.type === supportedType) {
        let url = window.URL || window.webkitURL;
        let src = url.createObjectURL(file);
        return src;
      } else {
        this.form.videoFile = null;
        this.$toastr.e({
          title: 'Lỗi',
          msg: 'Định dạng video phải là MP4',
          preventDuplicates: true,
        });
      }
      return null;
    },
    moveToDetailPage() {
      this.$router.push({
        name: 'movement_route_detail',
        params: {
          id: this.agendaId,
        },
      });
    },
    openLessonSectionEditModal() {
      this.$bvModal.show('movement-route-lesson-section-edit-modal');
    },
    openLessonSectionSelectModal() {
      this.$bvModal.show('movement-route-lesson-section-select-modal');
    },
    async callbackEditLessonSection(section) {
      this.lessonSections.splice(this.selectedSectionIndex, 1, { ...section });
      await this.updateSection(section);
    },
    async callbackCreateLessonSection(section) {
      this.lessonId && (section.exerciseMovementId = this.lessonId);
      const id = await this.createSection(section);
      this.lessonSections.push({ ...section, id });
    },
    onClickSelectSectionButton() {
      this.openLessonSectionSelectModal();
    },
    onClickAddSectionButton() {
      this.modalType = MODAL_TYPE.CREATE;
      this.selectedSection = {};
      this.openLessonSectionEditModal();
    },
    onClickEditLessonSection(section, index) {
      this.modalType = MODAL_TYPE.EDIT;
      this.selectedSection = { ...section };
      this.selectedSectionIndex = index;
      this.openLessonSectionEditModal();
    },
    resetModal() {
      this.modalType = null;
      this.selectedSection = {};
      this.selectedSectionIndex = null;
    },
    async onClickDeleteLessonSection(index, section) {
      if (this.lessonId) {
        await this.deleteSectionMapping(section);
      } else if (!section.source) {
        await this.deleteSection(section);
      }
      this.lessonSections.splice(index, 1);
    },
    showConfirmDelete(index, item) {
      this.$swal({
        title: 'Xác nhận',
        text: `Bạn có chắc chắn muốn xóa ${item.name} không?`,
        icon: 'warning',
        buttons: {
          cancel: {
            text: 'Huỷ bỏ',
            value: false,
            visible: true,
            className: '',
            closeModal: true,
          },
          confirm: {
            text: 'Xác nhận',
            value: true,
            visible: true,
            className: '',
            closeModal: true,
          },
        },
      }).then(
        function (result) {
          if (result) {
            // inactive all
            this.onClickDeleteLessonSection(index, item);
          }
        }.bind(this),
      );
    },
    onClickCancelButton() {
      this.moveToDetailPage();
    },
    onClickFileInput() {
      this.$refs['fileInput'].$refs['input'].click();
    },
    dragReorder({ oldIndex, newIndex }) {
      let draggedItem = this.lessonSections.splice(oldIndex, 1)[0];
      if (this.lessonId) {
        this.changeOrder(oldIndex, newIndex, draggedItem.id);
      } else {
        let draggedItemId = this.listCreatedSections.splice(oldIndex, 1)[0];
        this.listCreatedSections.splice(newIndex, 0, draggedItemId);
      }
      this.lessonSections.splice(newIndex, 0, draggedItem);
    },
    async changeOrder(oldIndex, newIndex, id) {
      try {
        const { meta, error } = await this.$api.put(
          'ExerciseMovement/Sections/Order',
          {
            exerciseMovementId: this.lessonId,
            exerciseMovementSectionId: id,
            current: oldIndex,
            new: newIndex,
          },
        );
        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message,
          });
        }
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
        });
      }
    },
    onUpload() {
      this.isImage = ['image/jpeg', 'image/png'].includes(this.file.type);
      this.isImageValid = this.file.size <= 512000;
      if (this.isImage && this.isImageValid) {
        this.showImageError = false;
        return (this.preview = URL.createObjectURL(this.file));
      }
      return (this.preview = null);
    },
    async loadData() {
      try {
        this.loading = true;
        const res = await this.$api.get(`${this.url.detail}/${this.lessonId}`);
        let data = res.data;
        this.form.code = data.code;
        this.form.name = data.name;
        this.form.coverId = data.coverId;
        this.form.description = data.description;
        this.form.videoUrl = data.videoUrl;
        this.form.practiceTime = data.practiceTime;
        this.preview = data.image?.url;

        this.lessonSections = sortBy(data.sections, 'order');
        this.originalLessonSections = [...this.lessonSections];
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    onClickSaveButton() {
      if (this.isCreateMode) {
        this.createLesson();
      } else {
        this.editLesson();
      }
    },
    async uploadImage(file) {
      // upload file
      const { error, data } = await this.$store.dispatch(
        'context/UPLOAD_IMAGE',
        file,
      );
      if (error) {
        this.$toastr.s({
          title: 'Lỗi !',
          msg: error,
        });
        return null;
      }
      return data.id;
    },
    getSectionsParams() {
      let unchangedSections = intersectionWith(
        this.lessonSections,
        this.originalLessonSections,
        isEqual,
      ).map((section) => {
        section.modelStatus = 0;
        return section;
      });
      let changedSections = intersectionWith(
        this.lessonSections,
        this.originalLessonSections,
        (a, b) => a.id === b.id,
      )
        .filter((section) => section.modelStatus !== 0)
        .map((section) => {
          section.modelStatus = 1;
          return section;
        });
      let deletedSections = differenceWith(
        this.originalLessonSections,
        this.lessonSections,
        (a, b) => a.id === b.id,
      ).map((section) => {
        section.modelStatus = 2;
        return section;
      });
      let createdSections = differenceWith(
        this.lessonSections,
        this.originalLessonSections,
        (a, b) => a.id === b.id,
      ).map((section) => {
        section.modelStatus = 3;
        return section;
      });
      return [
        ...unchangedSections,
        ...changedSections,
        ...deletedSections,
        ...createdSections,
      ];
    },
    async createLesson() {
      try {
        this.loading = true;
        let coverId = this.file
          ? await this.uploadImage(this.file)
          : this.form.coverId;
        let videoUrl = this.form.videoFile
          ? await this.uploadVideo(this.form.videoFile)
          : this.form.videoUrl;

        let params = {
          agendaId: this.agendaId,
          day: this.day,
          code: this.form.code,
          name: this.form.name,
          description: this.form.description,
          practiceTime: this.form.practiceTime,
          videoUrl,
          coverId: coverId,
          sections: this.listCreatedSections,
          isInserted: this.isInserted,
        };

        const res = await this.$api.post(this.url.create, params);
        if (res.error) {
          this.$toastr.e({
            title: 'Lỗi',
            msg: res.error.message,
            preventDuplicates: true,
          });
          return;
        }
        this.createLessonSuccess = true;
        this.$toastr.s({
          title: 'Thành công',
          msg: 'Tạo mới bài tập thành công',
          preventDuplicates: true,
        });
        this.moveToDetailPage();
      } catch (error) {
        this.createLessonSuccess = false;
        this.$toastr.e({
          title: 'Lỗi',
          msg: error?.message,
          preventDuplicates: true,
        });
      } finally {
        this.loading = false;
      }
    },
    async editLesson() {
      try {
        this.loading = true;
        let coverId = this.file
          ? await this.uploadImage(this.file)
          : this.form.coverId;
        let videoUrl = this.form.videoFile
          ? await this.uploadVideo(this.form.videoFile)
          : this.form.videoUrl;
        let params = {
          agendaId: this.agendaId,
          day: this.day,
          code: this.form.code,
          name: this.form.name,
          description: this.form.description,
          practiceTime: this.form.practiceTime,
          videoUrl,
          coverId: coverId,
          // sections: this.getSectionsParams(),
        };
        const res = await this.$api.put(
          `${this.url.edit}/${this.lessonId}`,
          params,
        );
        if (res.error) {
          this.$toastr.e({
            title: 'Lỗi',
            msg: res.error.message,
            preventDuplicates: true,
          });
          return;
        }
        this.moveToDetailPage();
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi',
          msg: error?.message,
          preventDuplicates: true,
        });
      } finally {
        this.loading = false;
      }
    },
    handleValidate() {
      this.$validator.validateAll().then((result) => {
        if (!this.preview) {
          this.showImageError = true;
        }
        if (result && this.preview) {
          this.showImageError = false;
          this.onClickSaveButton();
        }
        return;
      });
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.errors.has(ref);
      }
      return null;
    },
    handleUploaded({ file, url }) {
      this.form.videoFile = file;
      this.videoPreview = url;
    },
  },
};
</script>

<style lang="scss">
.movement-route-lesson-edit-page {
  .movement-route-lesson-edit-page__body {
    .image-upload {
      width: 120px;
      height: 120px;
      background-color: #f3f6f9;
      box-shadow: 0px 4px 4px 0px #000000 10%;
      position: relative;
      margin: auto;
    }

    .text-error {
      color: #ff5756;
      text-align: center;
      margin-top: 20px;
    }

    .pictureInput {
      position: relative;
    }

    .icon-edit {
      z-index: 10;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 18px);
      padding: 2px 6px;
      box-shadow: -1px 2px 10px rgba(0, 0, 0, 0.2);
      border-radius: 50%;
      display: flex;
      align-items: center;
      width: fit-content;
      background-color: #fff;
    }

    .movement-route-lesson-edit-page__body__file-input {
      height: 67px;
      border: 2px dashed #dddddd;

      span {
        font-size: 16px;
        color: #787a7d;
      }
    }
  }

  .button-info,
  .button-info:hover,
  .button-info:active {
    border-color: #def2f1 !important;
    color: #008479 !important;
    background-color: #def2f1 !important;
  }
}
</style>
